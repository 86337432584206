import {classes} from '../Components/CustomerDetailsOpen.st.css';
import {FIELDS_GAP} from '../../../../constants';
import {CustomerDetailsForm as ConsolidatedCustomerDetailsForm} from '../../../../Form/CustomerDetailsForm/CustomerDetailsForm';
import React, {useEffect} from 'react';
import {CustomerDetailsDataHook} from '../Components/CustomerDetailsOpen';
import {useControllerProps} from '../../../../Widget/ControllerContext';
import {useCustomerDetailsData} from '../WithCustomerDetailsData/WithCustomerDetailsData';
import {FormLoader} from '../../../../Form/FormLoader/FormLoader';
import {getCountryFromAddressFormValues} from '../../../../Form/form.utils';

export const CustomerDetailsForm = () => {
  const {
    formsStore: {areFormsLoaded},
  } = useControllerProps();
  const {customerDetailsFormData, updateContactCountry} = useCustomerDetailsData();

  const country = getCountryFromAddressFormValues(customerDetailsFormData.formValues);

  useEffect(
    () => {
      void updateContactCountry(country);
    },
    /* eslint-disable react-hooks/exhaustive-deps */ [country]
  );

  if (!areFormsLoaded) {
    return <FormLoader />;
  }

  return (
    <div
      data-hook={CustomerDetailsDataHook.customerDetailsForm}
      className={classes.forms}
      style={{
        gap: `${FIELDS_GAP}px`,
      }}>
      <ConsolidatedCustomerDetailsForm {...customerDetailsFormData} />
    </div>
  );
};
