import React, {useEffect, useState} from 'react';
import {useFormInstance} from '../../Form/useFormInstance';
import {ExtendedFieldsFragment} from '../../../../gql/graphql';
import {ButtonPriority, StatesButton, StatesButtonStates, ThreeDotsLoader} from 'wix-ui-tpa/cssVars';
import {useControllerProps} from '../../Widget/ControllerContext';
import {classes} from './FastFlowForm.st.css';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {CustomerDetailsForm} from '../../Form/CustomerDetailsForm/CustomerDetailsForm';
import {CustomerDetailsFormSection} from '../../../../types/checkoutApp.types';
import {
  getCustomFieldFromAdditionalInfoFormValues,
  getCustomerDetailsFormInitialState,
  getExtendedFieldValuesFromCustomerDetailsForm,
} from '../../Form/CustomerDetailsForm/CustomerDetailsForm.utils';
import {FormValues} from '@wix/form-fields';

export enum FastFlowFormDataHook {
  root = 'FastFlowForm.extendedFieldsForm',
  customerDetailsForm = 'FastFlowForm.customerDetailsForm',
  apply = 'FastFlowForm.apply',
}

const FastFlowFormInternal = ({extendedFields}: {extendedFields?: ExtendedFieldsFragment}) => {
  const localeKeys = useLocaleKeys();
  const {
    checkoutStore: {setFastFlowFormFields, checkout},
    formsStore: {dataExtendedFieldsTargets},
    checkoutSettingsStore: {checkoutSettings},
  } = useControllerProps();
  const customerDetailsFormInstance = useFormInstance(
    () =>
      getCustomerDetailsFormInitialState({
        checkoutSettings,
        extendedFields: checkout.extendedFields,
        customField: checkout.customField,
      }) as FormValues
  );

  const [buttonState, setButtonState] = useState<StatesButtonStates>(StatesButtonStates.IDLE);

  const onApplyClick = async () => {
    if (await customerDetailsFormInstance.isValid()) {
      const extendedFields = getExtendedFieldValuesFromCustomerDetailsForm(
        customerDetailsFormInstance.data.formValues,
        dataExtendedFieldsTargets
      );
      const customFieldValue = getCustomFieldFromAdditionalInfoFormValues(customerDetailsFormInstance.data.formValues);

      setButtonState(StatesButtonStates.IN_PROGRESS);
      await setFastFlowFormFields(extendedFields, customFieldValue);
    }
  };

  useEffect(
    () => {
      if (buttonState === StatesButtonStates.IN_PROGRESS) {
        setButtonState(StatesButtonStates.SUCCESS);
      }
    },
    /* eslint-disable react-hooks/exhaustive-deps */ [extendedFields, checkout.customField]
  );

  return (
    <div className={classes.root} data-hook={FastFlowFormDataHook.root}>
      <CustomerDetailsForm
        {...customerDetailsFormInstance.data}
        formSections={[CustomerDetailsFormSection.extendedFields, CustomerDetailsFormSection.additionalInfo]}
        dataHook={FastFlowFormDataHook.customerDetailsForm}
      />
      <StatesButton
        data-hook={FastFlowFormDataHook.apply}
        upgrade
        state={buttonState}
        idleContent={localeKeys.checkout.additionalInfo.custom_field.apply()}
        inProgressContent={<ThreeDotsLoader />}
        onNotificationEnd={() => setButtonState(StatesButtonStates.IDLE)}
        fullWidth
        priority={ButtonPriority.basicSecondary}
        className={classes.applyButton}
        onClick={() => {
          void onApplyClick();
        }}
      />
    </div>
  );
};

export const FastFlowForm = React.memo(FastFlowFormInternal);
